// Failbot needs to load first so we get errors from system lite.
import '@github-ui/failbot/failbot-error'

// Browser polyfills
import 'smoothscroll-polyfill'
import '@oddbird/popover-polyfill'
import '@github/arianotify-polyfill'

import {applyRemoveChildPatch} from '@github-ui/remove-child-patch'
import {applyInsertBeforePatch} from '@github-ui/insert-before-patch'
import {apply} from '@github/browser-support'
import '@github-ui/fetch-overrides'

apply()
if (typeof document !== 'undefined') {
  applyRemoveChildPatch()
  applyInsertBeforePatch()
}
